<template>
  <section class="tabs-content personal__content personal__content tabs-content--active">
    <AddClient @input="getClientList()" class="mb-5"/>

    <template v-for="(item, index) in clientList">
      <EditClient
        class="mb-4"
        :key="index"
        :item="item"
        @delete="getClientList()"
      />
    </template>
  </section>
</template>

<script>
import axios from 'axios';
import AddClient from '@/components/AddClient.vue';
import EditClient from '@/components/EditClient.vue';

export default {
  name: 'Clients',
  components: {
    AddClient,
    EditClient,
  },
  data() {
    return {
      clientList: [],
    };
  },
  computed: {
    apiUrl() {
      return this.$store.getters.getApiUrl;
    },
  },
  methods: {
    getClientList() {
      this.clientList = [];

      const url = new URL(this.apiUrl);
      url.searchParams.set('action', 'getClientList');

      axios
        .get(url)
        .then((response) => {
          this.clientList = response.data.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  created() {
    this.getClientList();
  },
};
</script>
